/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Button from 'components/CustomButtons/Button.js';
import Accordion from 'components/Accordion/Accordion.js';
// images
import doctor from 'assets/img/faces/Doctor.webp';
// styles
import homePageStyle from './homePageStyle.js';
const useStyles = makeStyles(homePageStyle);

export default function HomePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(classes.imgRaised, classes.imgRounded, classes.imgFluid);
  return (
    <>
      <Helmet>
        <title>Oral Surgeon in Visalia, CA</title>

        <meta
          name='description'
          content='Dr. J. Steven Supancic, Jr., DDS, MD, an experienced oral surgeon in Visalia, CA, offers a variety of dental services including wisdom teeth extraction and dental implants. Schedule an appointment with our Oral & Facial Surgical Arts team today.'
        />

        <link rel='canonical' href='https://oralsurgerymd.com' />

        <meta property='og:title' content='Oral Surgeon in Visalia, CA' />

        <meta
          property='og:description'
          content='Dr. J. Steven Supancic, Jr., DDS, MD, an experienced oral surgeon in Visalia, CA, offers a variety of dental services including wisdom teeth extraction and dental implants. Schedule an appointment with our Oral & Facial Surgical Arts team today.'
        />
      </Helmet>
      <div>
        <Header
          color='info'
          links={<HeaderLinks dropdownHoverColor='info' />}
          fixed
          // changeColorOnScroll={{
          //   height: 50,
          //   color: "info"
          // }}
        />
        <Parallax image={require('assets/img/osmdbg.jpg')} filter='dark' className={classes.parallax} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={doctor} height={200} width={160} alt='Dr. J. Steven Supancic, Jr., DDS, MD' className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Dr. J. Steven Supancic, Jr., DDS, MD</h3>
                    <h6>Oral Maxillofacial Surgeon</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classNames(classes.description)}>
              <p>
                Dr. Supancic is a native of California, born in Long Beach. He completed high school in 1975, and attended the University of California Berkeley where he obtained a Bachelor of Science
                Degree in engineering with highest honors. After working several years, he decided on a career change to dentistry and attended the University of California San Francisco School of
                Dentistry from 1984 to 1988. Upon obtaining his Doctorate of Dental Surgery (DDS) degree with highest honors, he entered a six year residency program at the University of Nebraska
                Medical Center. His Medical Doctor (MD) degree was awarded with distinction in 1991. This was followed by a two year residency in general surgery completed in 1993. He finished the
                program, which included facial plastic surgery training in 1994.{' '}
              </p>
              <p>
                Dr. Supancic has practiced the full scope of oral and facial plastic surgery in Visalia for twenty-five years. His primary mission is to provide the highest quality care in the most
                comfortable Christian environment. Dr. Supancic is married with three adult boys. In his spare time, he enjoys tennis, swimming, electronics, bowling, guitar, and skiing.{' '}
              </p>
            </div>
            <Clearfix />
            <GridContainer>
              <GridItem md={4} sm={12}>
                <InfoArea
                  vertical
                  className={classes.infoArea}
                  title='Professional Associations'
                  description={
                    <span>
                      <ul className={classes.infoAreaList}>
                        <li>American Association of Oral and Maxillofacial Surgeons</li>
                        <li>American Dental Association</li>
                        <li>Association of American Physicians and Surgeons</li>
                        <li>California Society of Oral and Maxillofacial Surgeons</li>
                        <li>California Dental Association</li>
                        <li>Tulare/King Dental Society</li>
                      </ul>
                    </span>
                  }
                  icon={function() {
                    return <i className={classNames(classes.icon, 'fas fa-handshake fa-2x')}></i>;
                  }}
                  iconColor='primary'
                />
              </GridItem>
              <GridItem md={4} sm={12}>
                <InfoArea
                  vertical
                  className={classes.infoArea}
                  title='Board Certification'
                  description={
                    <span>
                      <ul className={classes.infoAreaList}>
                        <li>Passed Written Qualifying Examination - October 1994</li>
                      </ul>
                    </span>
                  }
                  icon={function() {
                    return <i className={classNames(classes.icon, 'fas fa-certificate fa-2x')}></i>;
                  }}
                  iconColor='primary'
                />
              </GridItem>
              <GridItem md={4} sm={12}>
                <InfoArea
                  vertical
                  className={classes.infoArea}
                  title='Healthcare Facility Affiliations'
                  description={
                    <span>
                      <ul className={classes.infoAreaList}>
                        <li>Kaweah Delta Health Care District, Visalia, California</li>
                        <li>Visalia Children's Dental Surgery Center</li>
                      </ul>
                    </span>
                  }
                  icon={function() {
                    return <i className={classNames(classes.icon, 'fas fa-hospital fa-2x')}></i>;
                  }}
                  iconColor='primary'
                />
              </GridItem>

              <GridItem>
                <InfoArea
                  vertical
                  className={classes.infoArea}
                  title={`Visalia's Wisdom Teeth Expert`}
                  description={
                    <>
                      <p>
                        Seeking specialized care for wisdom teeth in Visalia? Look no further. Our oral surgery center, located centrally in Visalia, CA, has earned a reputation as the leading expert
                        in wisdom teeth removal and management. From accurate diagnostics to state-of-the-art extraction procedures, we ensure Visalia residents receive the highest standard of care.
                        With years of dedicated experience, we're not just experts; we're Visalia's trusted choice for all things related to wisdom teeth. Join countless satisfied patients and let us
                        address your wisdom teeth concerns in Visalia with unmatched expertise.
                      </p>
                      <div style={{ textAlign: 'center' }}>
                        <Link to={'/wisdom-teeth'}>
                          <Button color='primary' round>
                            Learn More
                          </Button>
                        </Link>
                      </div>
                    </>
                  }
                  icon={function() {
                    return <i className={classNames(classes.icon, 'fas fa-user-md fa-2x')}></i>;
                  }}
                  iconColor='primary'
                />
              </GridItem>

              <GridItem>
                <InfoArea
                  vertical
                  className={classes.infoArea}
                  title='When to See Us'
                  description={
                    <>
                      <Accordion
                        // active={0}
                        // activeColor='rose'
                        collapses={[
                          {
                            title: 'When to see an oral surgeon for wisdom teeth issues?',
                            content: (
                              <p>
                                If you experience pain, discomfort, swelling, or recurrent infections near the back of your mouth, it may be due to impacted or problematic wisdom teeth. An oral
                                surgeon, specializing in wisdom teeth evaluation and removal, can offer expert care.
                              </p>
                            )
                          },
                          {
                            title: 'Do all wisdom teeth need to be removed?',
                            content: (
                              <p>
                                Not necessarily. While many individuals experience complications with their wisdom teeth, not all wisdom teeth cause problems. Each tooth is assessed individually based
                                on its alignment, potential for impaction, and other dental health factors. A comprehensive evaluation helps in determining the appropriate action.
                              </p>
                            )
                          },
                          {
                            title: 'What are the signs of impacted wisdom teeth?',
                            content: (
                              <p>
                                Impacted wisdom teeth can lead to pain, swelling, and even infection. Other signs include bad breath, difficulty opening the mouth, and tenderness when biting or
                                chewing. If you encounter any of these symptoms, seeking an evaluation from an oral surgeon is crucial.
                              </p>
                            )
                          },
                          {
                            title: 'How long is the recovery period after wisdom teeth removal?',
                            content: (
                              <p>
                                Recovery times can vary, but most people start feeling better within a few days. It's essential to follow post-operative instructions, which may include taking
                                prescribed medications, adopting a soft food diet, and practicing good oral hygiene, to ensure a swift and smooth recovery.
                              </p>
                            )
                          },
                          {
                            title: 'Do I need a referral to consult an oral surgeon about wisdom teeth?',
                            content: (
                              <p>
                                While many patients are directed to oral surgeons by their general dentists, it's not always mandatory. If you have concerns or symptoms related to your wisdom teeth,
                                you can directly schedule a consultation with our oral surgeon specialist.
                              </p>
                            )
                          }
                        ]}
                      />
                      <div style={{ textAlign: 'center' }}>
                        <Link to={'/contact-us'}>
                          <Button color='primary' round>
                            Contact Us to Schedule a Consultation
                          </Button>
                        </Link>
                      </div>
                    </>
                  }
                  icon={function() {
                    return <i className={classNames(classes.icon, 'fas fa-question fa-2x')}></i>;
                  }}
                  iconColor='primary'
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
