/*eslint-disable*/
import React from 'react';
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
import PinDrop from '@material-ui/icons/PinDrop';
import Phone from '@material-ui/icons/Phone';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Check from '@material-ui/icons/Check';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Footer from 'components/Footer/Footer.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import Danger from 'components/Typography/Danger.js';

import contactUsStyle from './contactUsStyle.js';

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  // state
  const [messageSent, setMessageSent] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');

  function contactUs() {
    fetch(`${process.env.REACT_APP_API_URL}/api/contact-us`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message
      })
    }).then(
      result => {
        console.log(result);
        setMessageSent(true);
      },
      error => {
        console.log('error', error);
      }
    );
  }

  return (
    <>
      <Helmet>
        <title>Contact Us</title>

        <meta
          name='description'
          content="Reach out to Dr. J. Steven Supancic, Jr., DDS, MD, and his Visalia oral surgery team for any inquiries or to schedule an appointment. We're here to address your dental needs."
        />

        <link rel='canonical' href='https://oralsurgerymd.com/contact-us' />

        <meta property='og:title' content='Contact Us' />

        <meta
          property='og:description'
          content="Reach out to Dr. J. Steven Supancic, Jr., DDS, MD, and his Visalia oral surgery team for any inquiries or to schedule an appointment. We're here to address your dental needs."
        />
      </Helmet>
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor='info' />}
          fixed
          color='info'
          // changeColorOnScroll={{
          //   height: 300,
          //   color: "info"
          // }}
        />
        <div className={classes.bigMap}>
          <iframe
            className='google-map'
            src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ6YrQhlUvlYARAn7QD-T0-Wo&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`}
            style={{ width: '100%', height: '100%' }}
            frameBorder='0'
          ></iframe>
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.contactContent}>
            <div className={classes.container}>
              <h2 className={classes.title}>Send us a message</h2>
              <GridContainer>
                <GridItem md={6} sm={6}>
                  {messageSent ? (
                    <SnackbarContent
                      message={
                        <span>
                          <b>MESSAGE SENT:</b> We{"'"}ll get in touch with you as soon as possible.
                        </span>
                      }
                      // close
                      color='success'
                      icon={Check}
                    />
                  ) : (
                    <div>
                      <p>
                        <Danger>Please use this form for general practice inquiries only</Danger>
                        <br />
                        <br />
                      </p>
                      <form>
                        <CustomInput
                          labelText='Your Name'
                          id='float'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: name,
                            onChange: e => {
                              setName(e.target.value);
                            }
                          }}
                        />
                        <CustomInput
                          labelText='Email address'
                          id='float'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: email,
                            onChange: e => {
                              setEmail(e.target.value);
                            }
                          }}
                        />
                        <CustomInput
                          labelText='Phone'
                          id='float'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: phone,
                            onChange: e => {
                              setPhone(e.target.value);
                            }
                          }}
                        />
                        <CustomInput
                          labelText='Your message'
                          id='float'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            multiline: true,
                            rows: 6,
                            value: message,
                            onChange: e => {
                              setMessage(e.target.value);
                            }
                          }}
                        />
                        <div className={classes.textCenter}>
                          <Button color='primary' round onClick={contactUs}>
                            Contact us
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}
                </GridItem>
                <GridItem md={4} sm={4} className={classes.mlAuto}>
                  <InfoArea
                    vertical
                    className={classes.info}
                    title='Find us at the office'
                    description={<p style={{ textAlign: 'center' }}>2748 W Main St, Visalia, CA 93291</p>}
                    icon={PinDrop}
                    iconColor='primary'
                  />
                  <InfoArea
                    vertical
                    className={classes.info}
                    title='Give us a ring'
                    description={
                      <p style={{ textAlign: 'center' }}>
                        <a href='tel:+5596259770'>(559) 625-9770</a>
                      </p>
                    }
                    icon={Phone}
                    iconColor='primary'
                  />
                  {/* <InfoArea
                  className={classes.info}
                  title="Legal Information"
                  description={
                    <p>
                      Creative Tim Ltd. <br /> VAT · EN2341241 <br /> IBAN ·
                      EN8732ENGB2300099123 <br /> Bank · Great Britain Bank
                    </p>
                  }
                  icon={BusinessCenter}
                  iconColor="primary"
                /> */}
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href='/referring-doctors'
                      // target="_blank"
                      className={classes.block}
                    >
                      Referring Doctors
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>&copy; {1900 + new Date().getYear()}</div>
            </div>
          }
        />
      </div>
    </>
  );
}
